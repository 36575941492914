.ConetenedorContacto {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    text-align: center;
    width: 500px;
}

.maps {
    max-width: 500px;
    height: 400px;
}